
























import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'UCIrvineChem1LDLabReport3PurposeConcepts',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        purposeAnswer: null,
        purposeConceptsAnswer: null,
      },
      questions: [
        {
          text: 'In the space below, please write the purpose of the experiment.',
          inputName: 'purposeAnswer',
          maxInputLength: 750,
        },
        {
          text: 'In the space below, please write the concept(s) you were investigating and how they related to the guiding question(s).',
          inputName: 'purposeConceptsAnswer',
          maxInputLength: 2250,
        },
      ],
    };
  },
});
